<template>
    <breadcrumb btnText="返回" btnIcon="n-direction-left" @btn-click="$router.go(-1)" />
    <div class="category-create" v-if="!result.show">
        <category-form :data="category" :btnLoading="loading" @submit="submit" />
    </div>
    <operation-result
        v-model:show="result.show"
        :duration="result.duration"
        :nextText="result.nextText"
        :title="result.title"
        :status="result.status"
        @go-back="$router.go(-1)"
    />
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import CategoryForm from '@/components/forms/CategoryForm.vue';
import Breadcrumb from '@/components/layout/Breadcrumb.vue';
import OperationResult from '@/components/layout/OperationResult.vue';

@Options({
    components: {
        CategoryForm, Breadcrumb, OperationResult
    },
    data(){
        return {
            category: {
                published_at: {
                    date: {
                        year: '',
                        month: '',
                        day: ''
                    },
                    time: {
                        hour: '',
                        minute: '',
                        second: ''
                    }
                },
                status: {
                    label: '',
                    value: ''
                },
                permissions: {
                    label: '',
                    value: ''
                },
            },
            result: {
                show: false,
                title: '',
                duration: 0,
                toTitle: '',
                backText: '',
                nextText: '',
                status: 'SUCCESS'
            },
            loading: false
        }
    },
    beforeRouteUpdate(to, from, next){
        this.getCategory(Number(to.params.id));
        next();
    },
    mounted(){
        this.getCategory(Number(this.$route.params.id));
    },
    inject: ['menuReload', 'reload'],
    methods:{
        getCategory(id: number){
            this.$api.xrCategoryShow(id).then((res: any) => {
                let type: object = {},
                    status: object = {},
                    permissions: object = {},
                    content = '',
                    published_at = new Date(res.data.published_at);
                if(res.data.type === 'page') {
                    type = {
                        label: '单页面',
                        value: 'page'
                    };
                    content = res.data.content;
                } else if(res.data.type === 'equipment') {
                    type = {
                        label: '产品列表',
                        value: 'equipment'
                    };
                } else {
                    type = {
                        label: '文章列表',
                        value: 'article'
                    };
                }

                if(res.data.status === 'published'){
                    status = {
                        label: '发布',
                        value: 'published'
                    }
                } else if(res.data.status === 'draft'){
                    status = {
                        label: '草稿',
                        value: 'draft'
                    }
                } else {
                    status = {
                        label: '回收站',
                        value: 'deleted'
                    }
                }

                if(res.data.permissions === 'public'){
                    permissions = {
                        label: '公开',
                        value: 'public'
                    }
                } else {
                    permissions = {
                        label: '私有',
                        value: 'private'
                    }
                }

                this.category = {
                    id: res.data.id,
                    parent_id: res.data.parent_id,
                    type: type,
                    title: res.data.title,
                    keywords: res.data.keywords,
                    description: res.data.description,
                    status: status,
                    permissions: permissions,
                    thumb: res.data.thumb,
                    published_at: {
                        date: {
                            year: published_at.getFullYear(),
                            month: published_at.getMonth()+1,
                            day: published_at.getDate()
                        },
                        time: {
                            hour: published_at.getHours(),
                            minute: published_at.getMinutes(),
                            second: published_at.getSeconds() < 10 ? `0${published_at.getSeconds()}` : published_at.getSeconds()
                        }
                    },
                    content: content
                }
                
            })
        },
        submit(e: any){
            this.loading = true;
            this.$api.xrCategoryUpdate(Number(e.id), e).then((res: any) => {
                if(res.msg === 'success'){
                    this.result = {
                        show: true,
                        title: '栏目更新成功',
                        duration: 0,
                        status: 'SUCCESS'
                    }
                } else {
                    this.result = {
                        show: true,
                        title: '栏目更新失败',
                        duration: 0,
                        status: 'ERROR'
                    }
                }
                this.loading = false;
                this.menuReload();
            })
        }
    }
})

export default class CategoryEdit extends Vue{}
</script>

<style lang="scss" scoped>
.category-create{
    @extend .flex-column;
}
</style>